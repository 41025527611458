import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { PRODUCTQ, CUSTOMERQ, USERQ, INVOICEQ, SETTINGSQ, ITEM_SALESQ, getItemSalesRow, INVOICE_SETTINGSQ, PAYMETNSQ, getPaymentsFromRow, getExpenseFromRow, EXPENSEQ, getPartyFromRow, PARTYQ, getPurchaseFromRow, PURCHASEQ, getBackupStatusFromRow, BACKUP_STATUSQ } from './../_utility/sql-queries.constant';
import { getProductFromRow, getInvoiceFromRow, getCustomerFromRow, getUserFromRow, getSettingsFromRow, getInvoiceSettingsFromRow } from './../_utility/sql-queries.constant';
import { SETTINGS } from '../_models/settings.model';
import { CUSTOMER } from './../_models/customer.model';
import { INVOICE } from './../_models/invoice.model';
import { PRODUCT } from './../_models/product.model';
import { USER } from './../_models/user.model';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { ITEM_SALES } from '../_models/item-sales.model';
import { INVOICE_SETTINGS } from '../_models/invoice-settings.model';
import { DEFAULT_SIGNATURE } from '../_constants/icon.constant';
import { PAYMENT } from '../_models/payments.model';
import { EXPENSE } from '../_models/expense.model';
import { PARTY } from '../_models/party.model';
import { PURCHASE } from '../_models/purchase.model';
import { ITEM } from '../_models/item.model';
import { v4 as uuidv4 } from 'uuid';
import { take } from 'rxjs/operators';
import { BACKUP_STATUS } from '../_models/backup-status.model';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  getProductFromRow = getProductFromRow;
  getInvoiceFromRow = getInvoiceFromRow;
  getCustomerFromRow = getCustomerFromRow;
  getUserFromRow = getUserFromRow;
  getSettingsFromRow = getSettingsFromRow;
  getItemSalesRow = getItemSalesRow;
  getInvoiceSettingsFromRow = getInvoiceSettingsFromRow;
  getPaymentsFromRow = getPaymentsFromRow;
  getExpenseFromRow = getExpenseFromRow;
  getPartyFromRow = getPartyFromRow;
  getPurchaseFromRow = getPurchaseFromRow;
  getBackupStatusFromRow = getBackupStatusFromRow;
  private storage: SQLiteObject;
  productList = new BehaviorSubject([]);
  customerList = new BehaviorSubject([]);
  partyList = new BehaviorSubject([]);
  itemSales = new BehaviorSubject([]);
  invoiceList = new BehaviorSubject([]);
  purchaseList: BehaviorSubject<PURCHASE[]> = new BehaviorSubject([]);
  userList = new BehaviorSubject([]);
  paymentsList: BehaviorSubject<PAYMENT[]> = new BehaviorSubject([]);
  expenseList: BehaviorSubject<EXPENSE[]> = new BehaviorSubject([]);
  settingList = new BehaviorSubject([]);
  invoiceSettingList = new BehaviorSubject([]);
  private isDbReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private userAdded: BehaviorSubject<string> = new BehaviorSubject('');
  userAdded$ = this.userAdded.asObservable();

  constructor(private platform: Platform,
    private sqlite: SQLite,
    private httpClient: HttpClient,
    private toastService: ToastService,
    private sqlPorter: SQLitePorter) {
    this.platform.ready().then(async () => {
      this.sqlite.create({
        name: 'billing.db',
        location: 'default'
      })
        .then(async (db: SQLiteObject) => {
          this.storage = db;
          await this.createDatabase();
        });

    });
  }

  dbState() {
    return this.isDbReady.asObservable();
  }

  fetchProducts(): Observable<PRODUCT[]> {
    return this.productList.asObservable();
  }

  fetchCustomers(): Observable<CUSTOMER[]> {
    return this.customerList.asObservable();
  }

  fetchParties(): Observable<PARTY[]> {
    return this.partyList.asObservable();
  }

  fetchInvoices(): Observable<INVOICE[]> {
    return this.invoiceList.asObservable();
  }

  fetchPurchases(): Observable<PURCHASE[]> {
    return this.purchaseList.asObservable();
  }

  fetchItemsSales(): Observable<ITEM_SALES[]> {
    return this.itemSales.asObservable();
  }

  fetchUsers(): Observable<USER[]> {
    return this.userList.asObservable();
  }

  fetchSettings(): Observable<SETTINGS[]> {
    return this.settingList.asObservable();
  }

  fetchInvoiceSettings(): Observable<INVOICE_SETTINGS[]> {
    return this.invoiceSettingList.asObservable();
  }

  fetchPayments(): Observable<PAYMENT[]> {
    return this.paymentsList.asObservable();
  }

  fetchExpense(): Observable<EXPENSE[]> {
    return this.expenseList.asObservable();
  }

  async createDatabase() {
    await this.httpClient.get(
      'assets/dump.sql',
      { responseType: 'text' }
    ).toPromise().then(async (data) => {
      if (this.platform.is('desktop')) {
        data.split(';').forEach(async (element) => {
          const sqlStatement = element.split('\r\n').join(' ');
          //console.log(sqlStatement);
          await this.storage.executeSql(sqlStatement).then(() => {

          });
        });
        // await this.getTableInfo('settings', 'auto_send_sms', 'BOOLEAN', 'true');
        await this.getProducts();
        await this.getUsers();
        await this.getInvoices();
        await this.getPurchases();
        await this.getExpense();
        await this.getCustomers();
        await this.getItemSales();
        await this.getParties();
        await this.getSettings();
        await this.deletePaymentForAmountZero();
        await this.getPayments();
        await this.getInvoiceSettings();

        this.isDbReady.next(true);
        this.fetchUsers().subscribe(data => {
          if (data.length > 0) {
            const user: USER = data[0];
            if (!user.password || user.password.length <= 0 || user.password === 'undefined') {
              this.httpClient.post(environment.baseUrl + '/store/readByPhoneNumber.php', { id: user.mobile_number }).subscribe(async (data: any) => {
                let _default = data.password;
                user.password = data.password;
                await this.updateUser(user)
              })
            }
          }
        })
        return true;
      } else {
        await this.sqlPorter.importSqlToDb(this.storage, data)
          .then((onfulfilled) => {

          }, (onrejected) => {
            //console.log(onrejected);
          })
          .catch(error => console.error(error))
          .finally(async () => {
            await this.deletePaymentForAmountZero();
            await this.getProducts();
            await this.getUsers();
            await this.getInvoices();
            await this.getPurchases();
            await this.getExpense();
            await this.getCustomers();
            await this.getParties();
            await this.getItemSales();
            await this.getSettings();
            await this.getPayments();
            await this.getInvoiceSettings();
            this.isDbReady.next(true);
            return true;
          });
      }
    }).catch(error => {
      console.log(error);
    });

  }

  async backup(restore = false) {
    if (localStorage.getItem('uuid') && localStorage.getItem('uuid').length > 10) {
      await this.getBackupStatusByModule('products').then(async (backUpStatus) => {
        await this.getUnBackedUpProducts(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}product/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: PRODUCT[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const product = restoreData[i];
                await this.storage.executeSql('select * from products where id=?', [product.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updateProduct(product, false);
                  } else {
                    await this.addProduct(product);
                  }
                });
              }
              await this.updateRestoreStatusByModule('products');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('products');
            }
          });
        })
      })

      await this.getBackupStatusByModule('customers').then(async (backUpStatus) => {
        await this.getUnBackedUpCustomers(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}customer/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: CUSTOMER[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const customer = restoreData[i];
                await this.storage.executeSql('select * from customers where id=?', [customer.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updateCustomer(customer, false);
                  } else {
                    await this.addCustomer(customer);
                  }
                });
              }
              await this.updateRestoreStatusByModule('customers');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('customers');
            }
          });
        })
      })

      await this.getBackupStatusByModule('invoices').then(async (backUpStatus) => {
        await this.getUnBackedUpInvoice(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}invoice/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: INVOICE[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const product = restoreData[i];
                await this.storage.executeSql('select * from invoices where id=?', [product.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updateInvoice(product, false);
                  } else {
                    await this.addInvoice(product);
                  }
                });
              }
              await this.updateRestoreStatusByModule('invoices');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('invoices');
            }
          });
        })
      })

      await this.getBackupStatusByModule('settings').then(async (backUpStatus) => {
        await this.getUnBackedUpSettings(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}setting/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: SETTINGS[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const product = restoreData[i];
                await this.storage.executeSql('select * from settings where id=?', [product.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updateSettings(product, false);
                  } else {
                    await this.addSettings(product);
                  }
                });
              }
              await this.updateRestoreStatusByModule('settings');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('settings');
            }
          });
        })
      })

      await this.getBackupStatusByModule('item_sales').then(async (backUpStatus) => {
        await this.getUnBackedUpItemSaless(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}itemSales/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: ITEM_SALES[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const product = restoreData[i];
                await this.storage.executeSql('select * from item_sales where id=?', [product.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updateItemSales(product, false);
                  } else {
                    await this.addItemSales(product);
                  }
                });
              }
              await this.updateRestoreStatusByModule('item_sales');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('item_sales');
            }
          });
        })
      })

      await this.getBackupStatusByModule('invoice_settings').then(async (backUpStatus) => {
        await this.getUnBackedUpInvoiceSettings(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}invoiceSetting/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: INVOICE_SETTINGS[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const product = restoreData[i];
                await this.storage.executeSql('select * from invoice_settings where id=?', [product.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updateInvoiceSettings(product, false);
                  } else {
                    await this.addInvoiceSettings(product);
                  }
                });
              }
              await this.updateRestoreStatusByModule('invoice_settings');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('invoice_settings');
            }
          });
        })
      })

      await this.getBackupStatusByModule('parties').then(async (backUpStatus) => {
        await this.getUnBackedUpParties(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}party/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: PARTY[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const product = restoreData[i];
                await this.storage.executeSql('select * from parties where id=?', [product.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updateParty(product, false);
                  } else {
                    await this.addParty(product);
                  }
                });
              }
              await this.updateRestoreStatusByModule('parties');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('parties');
            }
          });
        })
      })

      await this.getBackupStatusByModule('user').then(async (backUpStatus) => {
        await this.getUnBackedUpUser(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}user/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: USER[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const product = restoreData[i];
                await this.storage.executeSql('select * from user where id=?', [product.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updateUser(product, false);
                  } else {
                    await this.addUser(product);
                  }
                });
              }
              await this.updateRestoreStatusByModule('user');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('user');
            }
          });
        })
      })

      await this.getBackupStatusByModule('payments').then(async (backUpStatus) => {
        await this.getUnBackedUpPayments(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}payment/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: PAYMENT[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const product = restoreData[i];
                await this.storage.executeSql('select * from payments where id=?', [product.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updatePayments(product, false);
                  } else {
                    await this.addPayment(product);
                  }
                });
              }
              await this.updateRestoreStatusByModule('payments');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('payments');
            }
          });
        })
      })

      await this.getBackupStatusByModule('expense').then(async (backUpStatus) => {
        await this.getUnBackedUpExpense(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}expense/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: EXPENSE[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const product = restoreData[i];
                await this.storage.executeSql('select * from expense where id=?', [product.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updateExpense(product, false);
                  } else {
                    await this.addExpense(product);
                  }
                });
              }
              await this.updateRestoreStatusByModule('expense');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('expense');
            }
          });
        })
      })

      await this.getBackupStatusByModule('purchase').then(async (backUpStatus) => {
        await this.getUnBackedUpPurchase(backUpStatus.lastBackup).then(async (unbackedData) => {
          this.httpClient.post(`${environment.awsUrl}purchase/all?restoredOn=${backUpStatus.lastRestored + 500}&uuid=${localStorage.getItem('uuid')}`, unbackedData).subscribe(async (restoreData: PURCHASE[]) => {
            if (restoreData.length > 0 && restore) {
              for (var i = 0; i < restoreData.length; i++) {
                const product = restoreData[i];
                await this.storage.executeSql('select * from purchase where id=?', [product.id]).then(async (res) => {
                  if (res.rows.length > 0) {
                    await this.updatePurchase(product, false);
                  } else {
                    await this.addPurchase(product);
                  }
                });
              }
              await this.updateRestoreStatusByModule('purchase');
            }
            if (unbackedData.length > 0) {
              await this.updateBackupStatusByModule('purchase');
            }
          });
        })
      })
    }
  }

  async wipeData() {
    if (this.platform.is('desktop')) {
      await this.storage.close().then(() => {
        //console.log('clear');
        this.setAllRecordsToNull();
        this.userAdded.next(new Date().toString());
        return;
      });
    } else {
      await this.sqlPorter.wipeDb(this.storage).then(() => {
        this.setAllRecordsToNull();
        this.userAdded.next(new Date().toString());
        return;
      });
    }
  }

  async setAllRecordsToNull() {
    this.partyList.next([]);
    this.userList.next([]);
    this.invoiceList.next([]);
    this.paymentsList.next([]);
    this.customerList.next([]);
    this.settingList.next([]);
    this.invoiceSettingList.next([]);
    this.expenseList.next([]);
    this.purchaseList.next([]);
    this.productList.next([]);
    this.itemSales.next([]);
  }

  async getTableInfo(tableName: string, columnName: string, dataType: string, _default: string) {
    return this.storage.executeSql('PRAGMA table_info(' + tableName + ')', []).then(res => {
      let foundColumn = false;
      for (var i = 0; i < res.rows.length; i++) {
        const colName = res.rows.item(i).name;
        if (colName === columnName) {
          foundColumn = true;
          break;
        }
      }
      if (!foundColumn) {
        if (tableName === 'user' && columnName === 'password') {
          this.fetchUsers().subscribe(data => {
            if (data.length > 0) {
              const user: USER = data[0];
              this.httpClient.post(environment.baseUrl + '/store/readByPhoneNumber.php', { id: user.mobile_number }).subscribe((data: any) => {
                _default = data;
                let updateQuery = '';
                updateQuery = 'ALTER TABLE user ADD COLUMN password TEXT DEFAULT `' + _default + '`';
                return this.storage.executeSql(updateQuery, []).then(res => {
                  ////console.log(res);
                }).catch(error => {
                  console.error(updateQuery);
                })
              })
            }
          })
        } else {
          if (tableName === 'user' && columnName === 'uuid') {
            localStorage.setItem('uuid', _default);
          }

          let updateQuery: string;

          if (dataType === 'INTEGER') {
            updateQuery = 'ALTER TABLE ' + tableName + ' ADD COLUMN ' + columnName + ' ' + dataType + ' DEFAULT ' + _default;
          } else {
            updateQuery = 'ALTER TABLE ' + tableName + ' ADD COLUMN ' + columnName + ' ' + dataType + ' DEFAULT `' + _default + '`';
          }
          console.log(updateQuery);
          return this.storage.executeSql(updateQuery, []).then(res => {
            ////console.log(res);
          }).catch(error => {
            console.error(updateQuery);
          })
        }
      }
    });
  }

  async getTableInfoWithUpdateQuery(tableName: string, columnName: string, dataType: string, _default: string, columnToSetValue: string) {
    return this.storage.executeSql('PRAGMA table_info(' + tableName + ')', []).then(res => {
      let foundColumn = false;
      for (var i = 0; i < res.rows.length; i++) {
        const colName = res.rows.item(i).name;
        if (colName === columnName) {
          foundColumn = true;
          break;
        }
      }
      if (!foundColumn) {
        this.storage.executeSql('ALTER TABLE ' + tableName + ' ADD COLUMN ' + columnName + ' ' + dataType + ' DEFAULT ' + _default, []).then(res => {
          return this.storage.executeSql('UPDATE ' + tableName + ' SET ' + columnName + '="' + columnToSetValue + '" WHERE ' + columnName + '="' + _default + '"').then(res => {

          })
        })
      }
    });
  }

  async getTableInfoWithUpdateQueryProfitLoss(tableName: string, columnName: string, dataType: string, _default: string, columnToSetValue: string) {
    return this.storage.executeSql('PRAGMA table_info(' + tableName + ')', []).then(res => {
      let foundColumn = false;
      for (var i = 0; i < res.rows.length; i++) {
        const colName = res.rows.item(i).name;
        if (colName === columnName) {
          foundColumn = true;
          break;
        }
      }
      if (!foundColumn) {
        this.storage.executeSql('ALTER TABLE ' + tableName + ' ADD COLUMN ' + columnName + ' ' + dataType + ' DEFAULT ' + _default, []).then(async (res) => {
          await this.addProfitLossInDesktop();
        })
      }
    });
  }

  async getTableInfoWithUpdateQueryTaxAmount(tableName: string, columnName: string, dataType: string, _default: string, columnToSetValue: string) {
    return this.storage.executeSql('PRAGMA table_info(' + tableName + ')', []).then(res => {
      let foundColumn = false;
      for (var i = 0; i < res.rows.length; i++) {
        const colName = res.rows.item(i).name;
        if (colName === columnName) {
          foundColumn = true;
          break;
        }
      }
      if (!foundColumn) {
        this.storage.executeSql('ALTER TABLE ' + tableName + ' ADD COLUMN ' + columnName + ' ' + dataType + ' DEFAULT ' + _default, []).then(async (res) => {
          if (tableName === 'invoices') {
            await this.addTotalTaxAmount();
          } else {
            await this.addTotalTaxAmountInPurchase();
          }
        })
      }
    });
  }

  async addTotalTaxAmount() {
    await this.getInvoices().then(async (invoices) => {
      for (let i = 0; i < invoices.length; i++) {
        if (!invoices[i].tax_amount || invoices[i].tax_amount === 0) {
          let taxAmount = 0;
          if (invoices[i].items) {
            let items: ITEM[] = JSON.parse(invoices[i].items);
            for (let j = 0; j < items.length; j++) {
              taxAmount += (items[j].price * items[j].tax / 100) * items[j].quantity;
            }
            await this.storage.executeSql('UPDATE invoices SET tax_amount="' + taxAmount + '" WHERE id="' + invoices[i].id + '"').then(res => {

            })
          }
        }
      }
    })
  }

  async addTotalTaxAmountInPurchase() {
    await this.getPurchases().then(async (invoices) => {
      for (let i = 0; i < invoices.length; i++) {
        if (!invoices[i].tax_amount || invoices[i].tax_amount === 0) {
          let taxAmount = 0;
          if (invoices[i].items) {
            let items: ITEM[] = JSON.parse(invoices[i].items);
            for (let j = 0; j < items.length; j++) {
              taxAmount += (items[j].price * items[j].tax / 100) * items[j].quantity;
            }
            await this.storage.executeSql('UPDATE purchase SET tax_amount="' + taxAmount + '" WHERE id="' + invoices[i].id + '"').then(res => {

            })
          }
        }
      }
    })
  }

  async addProfitLossInDesktop() {
    await this.getInvoices().then(async (invoices) => {
      for (let i = 0; i < invoices.length; i++) {
        if (!invoices[i].profit_loss || invoices[i].profit_loss === 0) {
          let profitLoss = 0;
          if (invoices[i].items) {
            let items: ITEM[] = JSON.parse(invoices[i].items);
            for (let j = 0; j < items.length; j++) {
              await this.getProductById(items[j].id).then(product => {
                if (product) {
                  profitLoss += (items[j].price - product.avg_cost_price) * items[j].quantity;
                }
              })
            }
            await this.storage.executeSql('UPDATE invoices SET profit_loss="' + profitLoss + '" WHERE id="' + invoices[i].id + '"').then(res => {

            })
          }
        }
      }
    })
  }

  async getProducts() {
    return this.storage.executeSql(PRODUCTQ.GET_ALL_PRODUCTS, []).then(res => {
      let products: PRODUCT[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          products.push(
            this.getProductFromRow(res.rows, i)
          );
        }
      }
      this.productList.next(products);
    })
  }

  async getCustomers() {
    return this.storage.executeSql(CUSTOMERQ.GET_ALL_CUSTOMER, []).then(res => {
      let customers: CUSTOMER[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          customers.push(
            this.getCustomerFromRow(res.rows, i)
          );
        }
      }
      this.customerList.next(customers);
    })
  }

  async getParties() {
    return this.storage.executeSql(PARTYQ.GET_ALL_PARTY, []).then(res => {
      let parties: PARTY[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          parties.push(
            this.getPartyFromRow(res.rows, i)
          );
        }
      }
      this.partyList.next(parties);
    })
  }

  async getUsers() {
    return this.storage.executeSql(USERQ.GET_ALL_USER, []).then(res => {
      let users: USER[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          users.push(
            this.getUserFromRow(res.rows, i)
          );
        }
      }
      this.userList.next(users);
    })
  }

  async getPayments() {
    return this.storage.executeSql(PAYMETNSQ.GET_ALL, []).then(res => {
      let payments: PAYMENT[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          payments.push(
            this.getPaymentsFromRow(res.rows, i)
          );
        }
      }
      this.paymentsList.next(payments);
    })
  }

  async getExpense() {
    return this.storage.executeSql(EXPENSEQ.GET_ALL, []).then(res => {
      let expense: EXPENSE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          expense.push(
            this.getExpenseFromRow(res.rows, i)
          );
        }
      }
      this.expenseList.next(expense);
    })
  }

  async getSettings() {
    return this.storage.executeSql(SETTINGSQ.GET_ALL, []).then(res => {
      let settings: SETTINGS[] = [];
      if (res.rows.length > 0) {
        if (res.rows.length > 1) {
          this.deleteSetting(this.getSettingsFromRow(res.rows, 1).id);
        }
        settings.push(
          this.getSettingsFromRow(res.rows, 0)
        );
      } else {
        if (localStorage.getItem('uuid')) {
          let setting: SETTINGS = {
            auto_backups: true,
            invoice_footer: 'Please visit us again!',
            prints: false,
            printer_name: '',
            show_tax_on_invoice: true,
            whatsapp_receipts: true,
            auto_send_sms: true,
            uuid: localStorage.getItem('uuid')
          }
          settings.push(setting);
          this.addSettings(setting);
        }
      }
      this.settingList.next(settings);
    })
  }

  async backupStatus() {
    return this.storage.executeSql(SETTINGSQ.GET_ALL, []).then(res => {
      let settings: SETTINGS[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          settings.push(
            this.getSettingsFromRow(res.rows, i)
          );
        }
      } else {
        let setting: SETTINGS = {
          auto_backups: true,
          invoice_footer: 'Please visit us again!',
          prints: false,
          printer_name: '',
          show_tax_on_invoice: true,
          whatsapp_receipts: true,
          auto_send_sms: true,
          uuid: localStorage.getItem('uuid')
        }
        settings.push(setting);
        this.addSettings(setting);
      }
      this.settingList.next(settings);
    })
  }

  async getInvoiceSettings() {
    return this.storage.executeSql(INVOICE_SETTINGSQ.GET_ALL, []).then(res => {
      let settings: INVOICE_SETTINGS[] = [];
      if (res.rows.length > 0) {
        if (res.rows.length > 1) {
          this.deleteInvoiceSetting(this.getInvoiceSettingsFromRow(res.rows, 1).id);
        }
        settings.push(
          this.getInvoiceSettingsFromRow(res.rows, 0)
        );
      } else {
        if (localStorage.getItem('uuid')) {
          let setting: INVOICE_SETTINGS = {
            signature: DEFAULT_SIGNATURE,
            invoice_prefix: 'INV-',
            terms_and_conditions: '["Order can be return in max 10 days.", "Warrenty of the product will be subject to the manufacturer terms and conditions.", "This is system generated invoice."]',
            template: 1,
            show_customer_name: true,
            uuid: localStorage.getItem('uuid')
          }
          settings.push(setting);
          this.addInvoiceSettings(setting);
        }
      }
      this.invoiceSettingList.next(settings);
    })
  }

  async getUser() {
    return this.storage.executeSql(USERQ.GET_ALL_USER, []).then(res => {
      let users: USER[] = [];
      if (res.rows.length > 0) {
        return this.getUserFromRow(res.rows, 0);
      }
    })
  }

  async getSetting() {
    return this.storage.executeSql(SETTINGSQ.GET_ALL, []).then(res => {
      let settings: SETTINGS[] = [];
      if (res.rows.length > 0) {
        return this.getSettingsFromRow(res.rows, 0);
      }
    })
  }

  async getInvoices() {
    return this.storage.executeSql(INVOICEQ.GET_ALL_INVOICE, []).then(res => {
      let invoices: INVOICE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          invoices.push(
            this.getInvoiceFromRow(res.rows, i)
          );
        }
      }
      this.invoiceList.next(invoices);
      return invoices;
    })
  }

  async getPurchases() {
    return this.storage.executeSql(PURCHASEQ.GET_ALL_PURCHASE, []).then(res => {
      let purchases: PURCHASE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          purchases.push(
            this.getPurchaseFromRow(res.rows, i)
          );
        }
      }
      this.purchaseList.next(purchases);
      return purchases;
    })
  }

  async getItemSales() {
    return this.storage.executeSql(ITEM_SALESQ.GET_ALL, []).then(res => {
      let item_sales: ITEM_SALES[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          item_sales.push(
            this.getItemSalesRow(res.rows, i)
          );
        }
      }
      this.itemSales.next(item_sales);
    })
  }

  async addProduct(product: PRODUCT) {
    if (!product.cost_price) {
      product.cost_price = product.price;
    }
    if (!product.avg_cost_price) {
      product.avg_cost_price = product.price;
    }
    let data = [product.id ? product.id : new Date().getTime(), product.name, product.price, product.tax, product.barcodes, product.unitType, product.unit, product.price_including_tax, product.quantity, product.cost_price, product.avg_cost_price, product.uuid, product.updated_on ? product.updated_on : new Date().getTime(), product.deleted_on ? product.deleted_on : null];
    return this.storage.executeSql(PRODUCTQ.INSERT_PRODUCT, data).then(res => {
      this.getProducts();
      return res.insertId;
    }).catch(() => {
      return null;
    });
  }

  async updateProduct(product: PRODUCT, refresh = true) {
    if (!product.cost_price) {
      product.cost_price = product.price;
    }
    if (!product.avg_cost_price) {
      product.avg_cost_price = product.price;
    }
    let data = [product.name, product.price, product.tax, product.barcodes, product.unitType, product.unit, product.price_including_tax, product.quantity, product.cost_price, product.avg_cost_price, new Date().getTime(), product.deleted_on ? product.deleted_on : null, product.id];
    return this.storage.executeSql(PRODUCTQ.UPDATE_PRODUCT_BY_ID, data).then(res => {
      if (refresh) {
        this.getProducts();
      }
    }).catch(() => {
      return null;
    });
  }

  async decreaseProductQuantity(quantity: number, productId: number) {
    let data = [new Date().getTime(), quantity, productId];
    return this.storage.executeSql(PRODUCTQ.DECREASE_PRODUCT_QUANTITY, data).then(res => {
      this.getProducts();
    });
  }

  async increaseProductQuantity(productId: number, quantity: number,) {
    let data = [new Date().getTime(), quantity, productId];
    return this.storage.executeSql(PRODUCTQ.INCREASE_PRODUCT_QUANTITY, data).then(res => {
      this.getProducts();
    }).catch(() => {
      return null;
    });
  }

  async deleteProduct(id: number) {
    return this.storage.executeSql(PRODUCTQ.DELETE_PRODUCT_BY_ID, [new Date().getTime(), new Date().getTime(), id]).then(res => {
      this.getProducts();
    }).catch(() => {
      return null;
    });
  }

  async getProductById(id: number) {
    return this.storage.executeSql(PRODUCTQ.GET_PRODUCT_BY_ID, [id]).then(res => {
      if (res.rows.length > 0) {
        return getProductFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }

  async getProductByBarcode(barcode: string) {
    return this.storage.executeSql(PRODUCTQ.GET_PRODUCT_BY_BARCODE, [barcode]).then(res => {
      if (res.rows.length > 0) {
        return getProductFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }

  async addInvoice(invoice: INVOICE) {
    invoice.profit_loss = invoice.profit_loss ? invoice.profit_loss : null;
    invoice.tax_amount = invoice.tax_amount ? invoice.tax_amount : null;
    let data = [invoice.id ? invoice.id : new Date().getTime(), invoice.customer_id, invoice.items, invoice.billdate, invoice.total, invoice.payment_type, invoice.discount, invoice.customer_name, invoice.mobile_number, invoice.paid, invoice.balance, invoice.profit_loss, invoice.tax_amount, invoice.uuid, invoice.updated_on ? invoice.updated_on : new Date().getTime(), invoice.deleted_on ? invoice.deleted_on : null];
    return this.storage.executeSql(INVOICEQ.INSERT_INVOICE, data).then(res => {
      this.getInvoices();
      return res;
    }).catch(() => {
      return null;
    });
  }

  async updateInvoice(invoice: INVOICE, refresh = true) {
    let data = [invoice.customer_id, invoice.items, invoice.billdate, invoice.total, invoice.payment_type, invoice.discount, invoice.customer_name, invoice.mobile_number, invoice.paid, invoice.balance, invoice.profit_loss, invoice.tax_amount, new Date().getTime(), invoice.deleted_on ? invoice.deleted_on : null, invoice.id];
    return this.storage.executeSql(INVOICEQ.UPDATE_INVOICE_BY_ID, data).then(res => {
      if (refresh) {
        this.getInvoices();
      }
    }).catch(() => {
      return null;
    });
  }

  async deleteInvoice(id: number) {
    return this.storage.executeSql(INVOICEQ.DELETE_INVOICE_BY_ID, [new Date().getTime(), new Date().getTime(), id]).then(res => {
      this.getInvoices();
    }).catch(() => {
      return null;
    });
  }

  async getInvoiceById(id: number) {
    return this.storage.executeSql(INVOICEQ.GET_INVOICE_BY_ID, [id]).then(res => {
      if (res.rows.length > 0) {
        return getInvoiceFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }

  async getInvoiceByNameAndDates(name: string, startDate: number, endDate: number) {
    return this.storage.executeSql(INVOICEQ.GET_INVOICE_BY_PRODUCT_NAME_DATE_RANGE, [name, startDate, endDate]).then(res => {
      let invoices: INVOICE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          invoices.push(
            this.getInvoiceFromRow(res.rows, i)
          );
        }
      }
      return invoices;
    }).catch(() => {
      return null;
    });
  }

  async getInvoiceByDates(startDate: number, endDate: number) {
    return this.storage.executeSql(INVOICEQ.GET_INVOICE_BY_DATE_RANGE, [startDate, endDate]).then(res => {
      let invoices: INVOICE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          invoices.push(
            this.getInvoiceFromRow(res.rows, i)
          );
        }
      }
      return invoices;
    }).catch(() => {
      return null;
    });
  }

  async getPurchasesByDates(startDate: number, endDate: number) {
    return this.storage.executeSql(PURCHASEQ.GET_INVOICE_BY_DATE_RANGE, [startDate, endDate]).then(res => {
      let purchases: PURCHASE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          purchases.push(
            this.getPurchaseFromRow(res.rows, i)
          );
        }
      }
      return purchases;
    }).catch(() => {
      return null;
    });
  }

  async getInvoiceByCustomerIdAndBalance(customerId: number) {
    return this.storage.executeSql(INVOICEQ.GET_INVOICE_BY_CUSTOMER_ID_WITH_BALANCE, [customerId]).then(res => {
      let invoices: INVOICE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          invoices.push(
            this.getInvoiceFromRow(res.rows, i)
          );
        }
      }
      return invoices;
    }).catch(() => {
      return null;
    });
  }

  //-- purchase

  async addPurchase(purchase: PURCHASE) {
    purchase.tax_amount = purchase.tax_amount ? purchase.tax_amount : null;
    let data = [purchase.id ? purchase.id : new Date().getTime(), purchase.party_id, purchase.items, purchase.billdate, purchase.total, purchase.payment_type, purchase.invoice_number, purchase.party_name, purchase.mobile_number, purchase.paid, purchase.balance, purchase.tax_amount, purchase.uuid, purchase.updated_on ? purchase.updated_on : new Date().getTime(), purchase.deleted_on ? purchase.deleted_on : null];
    return this.storage.executeSql(PURCHASEQ.INSERT_PURCHASE, data).then(res => {
      this.getPurchases();
      return res;
    }).catch(() => {
      return null;
    });
  }

  async updatePurchase(purchase: PURCHASE, refresh = true) {
    let data = [purchase.party_id, purchase.items, purchase.billdate, purchase.total, purchase.payment_type, purchase.invoice_number, purchase.party_name, purchase.mobile_number, purchase.paid, purchase.balance, purchase.tax_amount, new Date().getTime(), purchase.deleted_on ? purchase.deleted_on : null, purchase.id];
    return this.storage.executeSql(PURCHASEQ.UPDATE_PURCHASE_BY_ID, data).then(res => {
      if (refresh) {
        this.getPurchases();
      }
    }).catch(() => {
      return null;
    });
  }

  async deletePurchase(id: number) {
    return this.storage.executeSql(PURCHASEQ.DELETE_PURCHASE_BY_ID, [new Date().getTime(), new Date().getTime(), id]).then(res => {
      this.getPurchases();
    });
  }

  async getPurchaseById(id: number) {
    return this.storage.executeSql(PURCHASEQ.GET_PURCHASE_BY_ID, [id]).then(res => {
      if (res.rows.length > 0) {
        return getPurchaseFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }

  async getPurchaseByNameAndDates(name: string, startDate: number, endDate: number) {
    return this.storage.executeSql(PURCHASEQ.GET_PURCHASE_BY_PRODUCT_NAME_DATE_RANGE, [name, startDate, endDate]).then(res => {
      let invoices: PURCHASE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          invoices.push(
            this.getPurchaseFromRow(res.rows, i)
          );
        }
      }
      return invoices;
    }).catch(() => {
      return null;
    });
  }

  async getPurchaseByPartyIdAndBalance(partyId: number) {
    return this.storage.executeSql(PURCHASEQ.GET_PURCHASE_BY_PARTY_ID_WITH_BALANCE, [partyId]).then(res => {
      let invoices: PURCHASE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          invoices.push(
            this.getPurchaseFromRow(res.rows, i)
          );
        }
      }
      return invoices;
    }).catch(() => {
      return null;
    });
  }

  //------

  async addCustomer(customer: CUSTOMER) {
    let data = [customer.id ? customer.id : new Date().getTime(), customer.customer_name, customer.mobile_number, customer.customer_gst, customer.balance, customer.total_purchase, customer.uuid, customer.updated_on ? customer.updated_on : new Date().getTime(), customer.deleted_on ? customer.deleted_on : null];
    return this.storage.executeSql(CUSTOMERQ.INSERT_CUSTOMER, data).then(res => {
      this.getCustomers();
      return res;
    }).catch(() => {
      return null;
    });
  }

  async updateCustomer(customer: CUSTOMER, refresh = true) {
    let data = [customer.customer_name, customer.mobile_number, customer.customer_gst, customer.balance, customer.total_purchase, new Date().getTime(), customer.deleted_on ? customer.deleted_on : null, customer.id];
    return this.storage.executeSql(CUSTOMERQ.UPDATE_CUSTOMER_BY_ID, data).then(res => {
      if (refresh) {
        this.getCustomers();
      }
    }).catch(() => {
      return null;
    });
  }

  async deleteCustomer(id: number) {
    return this.storage.executeSql(CUSTOMERQ.DELETE_CUSTOMER_BY_ID, [new Date().getTime(), new Date().getTime(), id]).then(res => {
      this.getCustomers();
    }).catch(() => {
      return null;
    });
  }

  async getCustomerById(id: number) {
    return this.storage.executeSql(CUSTOMERQ.GET_CUSTOMER_BY_ID, [id]).then(res => {
      if (res.rows.length > 0) {
        return getCustomerFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }

  async getCustomerInvoicesByIdAndDate(customerId: number, start: number, end: number) {
    let data = [customerId, start, end];
    return this.storage.executeSql(INVOICEQ.GET_ALL_BY_CUSTOMER_ID_AND_DATE, data).then(res => {
      let invoices: INVOICE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          invoices.push(
            this.getInvoiceFromRow(res.rows, i)
          );
        }
      }
      return invoices;
    }).catch(() => {
      return null;
    });
  }

  async getTotalPurchaseCustomerByIdAndDate(customerId: number, start: number, end: number) {
    let data = [customerId, start, end];
    return this.storage.executeSql(INVOICEQ.GET_TOTAL_BY_CUSTOMER_ID_AND_DATE, data).then(res => {
      if (res.rows.length > 0) {
        return res.rows.item(0)["SUM(total)"]
      }
    }).catch(() => {
      return null;
    });
  }

  //-----------------
  async addParty(party: PARTY) {
    let data = [party.id ? party.id : new Date().getTime(), party.name, party.mobile_number, party.gst, party.balance, party.total_purchase, party.uuid, party.updated_on ? party.updated_on : new Date().getTime(), party.deleted_on ? party.deleted_on : null];
    return this.storage.executeSql(PARTYQ.INSERT_PARTY, data).then(res => {
      this.getParties();
      return res;
    }).catch(() => {
      return null;
    });
  }

  async updateParty(party: PARTY, refresh = true) {
    let data = [party.name, party.mobile_number, party.gst, party.balance, party.total_purchase, new Date().getTime(), party.deleted_on ? party.deleted_on : null, party.id];
    return this.storage.executeSql(PARTYQ.UPDATE_PARTY_BY_ID, data).then(res => {
      if (refresh) {
        this.getParties();
      }
    }).catch(() => {
      return null;
    });
  }

  async deleteParty(id: number) {
    return this.storage.executeSql(PARTYQ.DELETE_PARTY_BY_ID, [new Date().getTime(), new Date().getTime(), id]).then(res => {
      this.getParties();
    }).catch(() => {
      return null;
    });
  }

  async getPartyById(id: number) {
    return this.storage.executeSql(PARTYQ.GET_PARTY_BY_ID, [id]).then(res => {
      if (res.rows.length > 0) {
        return getPartyFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }

  // async getCustomerInvoicesByIdAndDate(customerId: number, start: number, end: number) {
  //   let data = [customerId, start, end];
  //   return this.storage.executeSql(INVOICEQ.GET_ALL_BY_CUSTOMER_ID_AND_DATE, data).then(res => {
  //     let invoices: INVOICE[] = [];
  //     if (res.rows.length > 0) {
  //       for (var i = 0; i < res.rows.length; i++) {
  //         invoices.push(
  //           this.getInvoiceFromRow(res.rows, i)
  //         );
  //       }
  //     }
  //     return invoices;
  //   });
  // }

  // async getTotalPurchasePartyByIdAndDate(customerId: number, start: number, end: number) {
  //   let data = [customerId, start, end];
  //   return this.storage.executeSql(INVOICEQ.GET_TOTAL_BY_CUSTOMER_ID_AND_DATE, data).then(res => {
  //     if (res.rows.length > 0) {
  //       return res.rows.item(0)["SUM(total)"]
  //     }
  //   });
  // }
  //------------------------------------

  async addUser(user: USER) {
    if (!(user.upi && user.upi.length > 0)) {
      user.upi = '';
    }
    let data = [user.id ? user.id : new Date().getTime(), user.user_full_name, user.email, user.mobile_number, user.shop_address, user.logo, user.shop_name, user.gst, user.upi, user.password, user.uuid, user.updated_on ? user.updated_on : new Date().getTime(), user.deleted_on ? user.deleted_on : null];
    return this.storage.executeSql(USERQ.INSERT_USER, data).then(res => {
      this.getUsers();
      this.userAdded.next(new Date().getTime() + '');
    }).catch(() => {
      return null;
    });
  }

  async updateUser(user: USER, refresh = true) {
    if (!(user.upi && user.upi.length > 0)) {
      user.upi = '';
    }
    let data = [user.user_full_name, user.email, user.mobile_number, user.shop_address, user.logo, user.shop_name, user.gst, user.upi, user.password, user.uuid, new Date().getTime(), user.deleted_on ? user.deleted_on : null, user.id];
    return this.storage.executeSql(USERQ.UPDATE_USER_BY_ID, data).then(res => {
      if (refresh) {
        this.getUsers();
      }
    }).catch(() => {
      return null;
    });
  }

  async deleteUser(id: number) {
    return this.storage.executeSql(USERQ.DELETE_USER_BY_ID, [new Date().getTime(), new Date().getTime(), id]).then(res => {
      this.getUsers();
    }).catch(() => {
      return null;
    });
  }

  async getUserById(id: number) {
    return this.storage.executeSql(USERQ.GET_USER_BY_ID, [id]).then(res => {
      if (res.rows.length > 0) {
        return getUserFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }

  async addSettings(settings: SETTINGS) {
    let data = [settings.id ? settings.id : new Date().getTime(), settings.auto_backups, settings.prints, settings.show_tax_on_invoice, settings.invoice_footer, settings.whatsapp_receipts, settings.printer_name, settings.auto_send_sms, settings.uuid, settings.updated_on ? settings.updated_on : new Date().getTime(), settings.deleted_on ? settings.deleted_on : null];
    return this.storage.executeSql(SETTINGSQ.INSERT, data).then(res => {
      this.getSettings();
    }).catch(() => {
      return null;
    });
  }

  async updateSettings(settings: SETTINGS, refresh = true) {
    let data = [settings.auto_backups, settings.prints, settings.show_tax_on_invoice, settings.invoice_footer, settings.whatsapp_receipts, settings.printer_name, settings.auto_send_sms, new Date().getTime(), settings.deleted_on ? settings.deleted_on : null, settings.id];
    return this.storage.executeSql(SETTINGSQ.UPDATE_BY_ID, data).then(res => {
      if (refresh) {
        this.getSettings();
      }
    }).catch(() => {
      return null;
    });
  }

  async deleteSetting(id: number) {
    return this.storage.executeSql(SETTINGSQ.DELETE_BY_ID, [id]).then(res => {
      this.getSettings();
    }).catch(() => {
      return null;
    });
  }

  async getSettingById(id: number) {
    return this.storage.executeSql(SETTINGSQ.GET_BY_ID, [id]).then(res => {
      if (res.rows.length > 0) {
        return getSettingsFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }

  async addItemSales(item_sales: ITEM_SALES) {
    if (item_sales.quantity === 0) {
      return;
    }
    let data = [item_sales.id ? item_sales.id : new Date().getTime(), item_sales.productId, item_sales.quantity, item_sales.date, item_sales.action, item_sales.invoice_id, item_sales.remaining_stock, item_sales.uuid, item_sales.updated_on ? item_sales.updated_on : new Date().getTime(), item_sales.deleted_on ? item_sales.deleted_on : null];
    return this.storage.executeSql(ITEM_SALESQ.INSERT, data).then(res => {
      return res;
    }).catch(() => {
      return null;
    });
  }

  async updateItemSales(item_sales: ITEM_SALES, refresh = true) {
    if (item_sales.quantity === 0) {
      return;
    }
    let data = [item_sales.productId, item_sales.quantity, item_sales.date, item_sales.action, item_sales.invoice_id, item_sales.remaining_stock, item_sales.uuid, new Date().getTime(), item_sales.deleted_on ? item_sales.deleted_on : null, item_sales.id];
    return this.storage.executeSql(ITEM_SALESQ.UPDATE_BY_ID, data).then(res => {
      if (refresh) {
        this.getItemSales();
      }
    }).catch(() => {
      return null;
    });
  }

  async deleteItemSales(productId: number, invoiceId: number) {
    let data = [new Date().getTime(), new Date().getTime(), productId, invoiceId];
    return this.storage.executeSql(ITEM_SALESQ.DELETE_BY_PRODUCT_AND_INVOICE_ID, data).then(res => {
      return res;
    }).catch(() => {
      return null;
    });
  }

  async getItemSalesByProductIdAndDate(productId: number, start: number, end: number) {
    let data = [productId, start, end];
    return this.storage.executeSql(ITEM_SALESQ.GET_ALL_BY_PRODUCT_ID_AND_DATE, data).then(res => {
      let itemsSalesReport: ITEM_SALES[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          itemsSalesReport.push(
            this.getItemSalesRow(res.rows, i)
          );
        }
      }
      return itemsSalesReport;
    }).catch(() => {
      return null;
    });
  }

  async getItemSalesByProductIdAndDateAsc(productId: number, start: number, end: number) {
    let data = [productId, start, end];
    return this.storage.executeSql(ITEM_SALESQ.GET_ALL_BY_PRODUCT_ID_AND_DATE, data).then(res => {
      let itemsSalesReport: ITEM_SALES[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          itemsSalesReport.push(
            this.getItemSalesRow(res.rows, i)
          );
        }
      }
      return itemsSalesReport;
    }).catch(() => {
      return null;
    });
  }

  async addInvoiceSettings(settings: INVOICE_SETTINGS) {
    let data = [settings.id ? settings.id : new Date().getTime(), settings.signature, settings.invoice_prefix, settings.terms_and_conditions, settings.show_customer_name, settings.template, settings.uuid, settings.updated_on ? settings.updated_on : new Date().getTime(), settings.deleted_on ? settings.deleted_on : null];
    return this.storage.executeSql(INVOICE_SETTINGSQ.INSERT, data).then(res => {
      this.getInvoiceSettings();
    }).catch(() => {
      return null;
    });
  }

  async updateInvoiceSettings(settings: INVOICE_SETTINGS, refresh = true) {
    let data = [settings.signature, settings.invoice_prefix, settings.terms_and_conditions, settings.show_customer_name, settings.template, new Date().getTime(), settings.deleted_on ? settings.deleted_on : null, settings.id];
    return this.storage.executeSql(INVOICE_SETTINGSQ.UPDATE_BY_ID, data).then(res => {
      if (refresh) {
        this.getInvoiceSettings();
      }
    }).catch(() => {
      return null;
    });
  }

  async deleteInvoiceSetting(id: number) {
    return this.storage.executeSql(INVOICE_SETTINGSQ.DELETE_BY_ID, [id]).then(res => {
      this.getInvoiceSettings();
    }).catch(() => {
      return null;
    });
  }

  async getInvoiceSettingById(id: number) {
    return this.storage.executeSql(INVOICE_SETTINGSQ.GET_BY_ID, [id]).then(res => {
      if (res.rows.length > 0) {
        return getInvoiceSettingsFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }

  async addPayment(payment: PAYMENT) {
    let data = [payment.id ? payment.id : new Date().getTime(), payment.date, payment.category, payment._from, payment._to, payment.invoice_id, payment.direction, payment.amount, payment.payment_type, payment.uuid, payment.updated_on ? payment.updated_on : new Date().getTime(), payment.deleted_on ? payment.deleted_on : null];
    return this.storage.executeSql(PAYMETNSQ.INSERT, data).then(res => {
      this.getPayments();
      return res;
    }).catch(() => {
      return null;
    });
  }

  async updatePayments(payment: PAYMENT, refresh = true) {
    let data = [payment.date, payment.category, payment._from, payment._to, payment.invoice_id, payment.direction, payment.amount, payment.payment_type, new Date().getTime(), payment.deleted_on ? payment.deleted_on : null, payment.id];
    return this.storage.executeSql(PAYMETNSQ.UPDATE_BY_ID, data).then(res => {
      if (refresh) {
        this.getPayments();
      }
    }).catch(() => {
      return null;
    });
  }

  async deletePayment(id: number) {
    return this.storage.executeSql(PAYMETNSQ.DELETE_BY_ID, [new Date().getTime(), new Date().getTime(), id]).then(res => {
      this.getPayments();
    }).catch(() => {
      return null;
    });
  }

  async deletePaymentForAmountZero() {
    return this.storage.executeSql(PAYMETNSQ.DELETE_PAYMENT_FOR_PAID_ZERO, []).then(res => {
      this.getPayments();
    }).catch(() => {
      return null;
    });
  }

  async deletePaymentByInvoiceId(invoiceId: number) {
    return this.storage.executeSql(PAYMETNSQ.DELETE_BY_INVOICE_ID, [new Date().getTime(), new Date().getTime(), invoiceId]).then(res => {
      this.getPayments();
    }).catch(() => {
      return null;
    });
  }

  async deletePaymentByExpense(invoiceId: number, expenseCategory: string) {
    return this.storage.executeSql(PAYMETNSQ.DELETE_BY_EXPENSE, [new Date().getTime(), new Date().getTime(), invoiceId, expenseCategory]).then(res => {
      this.getPayments();
    }).catch(() => {
      return null;
    });
  }



  async deletePaymentByPurchaseId(purchaseId: number) {
    return this.storage.executeSql(PAYMETNSQ.DELETE_BY_PURCHASE_ID, [new Date().getTime(), new Date().getTime(), purchaseId]).then(res => {
      this.getPayments();
    }).catch(() => {
      return null;
    });
  }

  async getPaymentById(id: number) {
    return this.storage.executeSql(PAYMETNSQ.GET_BY_ID, [id]).then(res => {
      if (res.rows.length > 0) {
        return getPaymentsFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }

  async getPaymentByCustomerId(customerId: number) {
    return this.storage.executeSql(PAYMETNSQ.GET_PAYMENTS_BY_CUSTOMER_ID, [customerId]).then(res => {
      if (res.rows.length > 0) {
        let payments: PAYMENT[] = [];
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            payments.push(
              this.getPaymentsFromRow(res.rows, i)
            );
          }
        }
        return payments;
      }
    }).catch(() => {
      return null;
    });
  }

  async getPaymentByPartyId(partyId: number) {
    return this.storage.executeSql(PAYMETNSQ.GET_PAYMENTS_TO_PARTY_ID, [partyId]).then(res => {
      if (res.rows.length > 0) {
        let payments: PAYMENT[] = [];
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            payments.push(
              this.getPaymentsFromRow(res.rows, i)
            );
          }
        }
        return payments;
      }
    }).catch(() => {
      return null;
    });
  }

  async getIncomeByDate(start: number, end: number) {
    return this.storage.executeSql(PAYMETNSQ.GET_INCOME_BY_DATE, [start, end]).then(res => {
      if (res.rows.length > 0) {
        let payments: PAYMENT[] = [];
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            payments.push(
              this.getPaymentsFromRow(res.rows, i)
            );
          }
        }
        return payments;
      }
    }).catch(() => {
      return null;
    });
  }

  async getPaymentsByDateType(start: number, end: number, paymentType: string) {
    return this.storage.executeSql(PAYMETNSQ.GET_INCOME_BY_DATE_AND_TYPE, [start, end, paymentType]).then(res => {
      if (res.rows.length > 0) {
        let payments: PAYMENT[] = [];
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            payments.push(
              this.getPaymentsFromRow(res.rows, i)
            );
          }
        }
        return payments;
      }
    }).catch(() => {
      return null;
    });
  }

  async getOutgoingByDateType(start: number, end: number, paymentType: string) {
    return this.storage.executeSql(PAYMETNSQ.GET_OUTGOING_BY_DATE_AND_TYPE, [start, end, paymentType]).then(res => {
      if (res.rows.length > 0) {
        let payments: PAYMENT[] = [];
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            payments.push(
              this.getPaymentsFromRow(res.rows, i)
            );
          }
        }
        return payments;
      }
    }).catch(() => {
      return null;
    });
  }

  async getOutgoing(start: number, end: number) {
    return this.storage.executeSql(PAYMETNSQ.GET_OUTGOING_BY_DATE, [start, end]).then(res => {
      if (res.rows.length > 0) {
        let payments: PAYMENT[] = [];
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            payments.push(
              this.getPaymentsFromRow(res.rows, i)
            );
          }
        }
        return payments;
      }
    }).catch(() => {
      return null;
    });
  }

  async addExpense(expense: EXPENSE) {
    let data = [expense.id ? expense.id : new Date().getTime(), expense.date, expense.category, expense.description, expense.vendor_id, expense.total_cost, expense.paid, expense.balance, expense.payment_type, expense.uuid, expense.updated_on ? expense.updated_on : new Date().getTime(), expense.deleted_on ? expense.deleted_on : null];
    return this.storage.executeSql(EXPENSEQ.INSERT, data).then(res => {
      this.getExpense();
      return res;
    }).catch(() => {
      return null;
    });
  }

  async updateExpense(expense: EXPENSE, refresh = true) {
    let data = [expense.date, expense.category, expense.description, expense.vendor_id, expense.total_cost, expense.paid, expense.balance, expense.payment_type, new Date().getTime(), expense.deleted_on ? expense.deleted_on : null, expense.id];
    return this.storage.executeSql(EXPENSEQ.UPDATE_BY_ID, data).then(res => {
      if (refresh) {
        this.getExpense();
      }
    }).catch(() => {
      return null;
    });
  }

  async deleteExpense(id: number) {
    return this.storage.executeSql(EXPENSEQ.DELETE_BY_ID, [new Date().getTime(), new Date().getTime(), id]).then(res => {
      this.getExpense();
    }).catch(() => {
      return null;
    });
  }

  async getExpenseById(id: number) {
    return this.storage.executeSql(EXPENSEQ.GET_BY_ID, [id]).then(res => {
      if (res.rows.length > 0) {
        return getExpenseFromRow(res.rows, 0);
      }
    }).catch(() => {
      return null;
    });
  }


  async getPartyPurchaseByIdAndDate(customerId: number, start: number, end: number) {
    let data = [customerId, start, end];
    return this.storage.executeSql(PURCHASEQ.GET_ALL_BY_PARTY_ID_AND_DATE, data).then(res => {
      let purchases: PURCHASE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          purchases.push(
            this.getPurchaseFromRow(res.rows, i)
          );
        }
      }
      return purchases;
    }).catch(() => {
      return null;
    });
  }

  async getTotalPurchasePartyByIdAndDate(customerId: number, start: number, end: number) {
    let data = [customerId, start, end];
    return this.storage.executeSql(PURCHASEQ.GET_TOTAL_BY_PARTY_ID_AND_DATE, data).then(res => {
      if (res.rows.length > 0) {
        return res.rows.item(0)["SUM(total)"]
      }
    }).catch(() => {
      return null;
    });
  }

  async getBackupStatusByModule(module: string) {
    return this.storage.executeSql(BACKUP_STATUSQ.GET_BY_MODULE, [module]).then(res => {
      if (res.rows.length > 0) {
        return getBackupStatusFromRow(res.rows, 0);
      } else {
        const moduleDate = new Date();
        moduleDate.setFullYear(1990);
        const moduleObj: BACKUP_STATUS = {
          uuid: localStorage.getItem('uuid'),
          lastBackup: moduleDate.getTime(),
          lastRestored: moduleDate.getTime(),
          module: module
        };
        return this.storage.executeSql(BACKUP_STATUSQ.INSERT, [moduleObj.uuid, moduleObj.lastBackup, moduleObj.lastRestored, moduleObj.module]).then(res => {
          return moduleObj;
        });
      }
    })
  }

  async deleteBackupStatusByModule() {
    return this.storage.executeSql(BACKUP_STATUSQ.DELETE, []).then(res => {
    });
  }

  async updateBackupStatusByModule(module: string) {
    return this.storage.executeSql(BACKUP_STATUSQ.UPDATE_LASTBACKUP, [new Date().getTime() + 500, module]).then(res => {

    });
  }

  async updateRestoreStatusByModule(module: string) {
    return this.storage.executeSql(BACKUP_STATUSQ.UPDATE_LASTRESTORED, [new Date().getTime(), module]).then(res => {

    });
  }

  async getUnBackedUpProducts(lastBackup: number) {
    return this.storage.executeSql(PRODUCTQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let products: PRODUCT[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          products.push(
            this.getProductFromRow(res.rows, i)
          );
        }
      }
      return products;
    })
  }

  async getUnBackedUpCustomers(lastBackup: number) {
    return this.storage.executeSql(CUSTOMERQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let objects: CUSTOMER[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          objects.push(
            this.getCustomerFromRow(res.rows, i)
          );
        }
      }
      return objects;
    })
  }

  async getUnBackedUpSettings(lastBackup: number) {
    return this.storage.executeSql(SETTINGSQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let objects: SETTINGS[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          objects.push(
            this.getSettingsFromRow(res.rows, i)
          );
        }
      }
      return objects;
    })
  }

  async getUnBackedUpInvoiceSettings(lastBackup: number) {
    return this.storage.executeSql(INVOICE_SETTINGSQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let objects: INVOICE_SETTINGS[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          objects.push(
            this.getInvoiceSettingsFromRow(res.rows, i)
          );
        }
      }
      return objects;
    })
  }

  async getUnBackedUpUser(lastBackup: number) {
    return this.storage.executeSql(USERQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let objects: USER[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          objects.push(
            this.getUserFromRow(res.rows, i)
          );
        }
      }
      return objects;
    })
  }

  async getUnBackedUpItemSaless(lastBackup: number) {
    return this.storage.executeSql(ITEM_SALESQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let objects: ITEM_SALES[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          objects.push(
            this.getItemSalesRow(res.rows, i)
          );
        }
      }
      return objects;
    })
  }

  async getUnBackedUpPurchase(lastBackup: number) {
    return this.storage.executeSql(PURCHASEQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let objects: PURCHASE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          objects.push(
            this.getPurchaseFromRow(res.rows, i)
          );
        }
      }
      return objects;
    })
  }

  async getUnBackedUpInvoice(lastBackup: number) {
    return this.storage.executeSql(INVOICEQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let objects: INVOICE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          objects.push(
            this.getInvoiceFromRow(res.rows, i)
          );
        }
      }
      return objects;
    })
  }

  async getUnBackedUpExpense(lastBackup: number) {
    return this.storage.executeSql(EXPENSEQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let objects: EXPENSE[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          objects.push(
            this.getExpenseFromRow(res.rows, i)
          );
        }
      }
      return objects;
    })
  }

  async getUnBackedUpParties(lastBackup: number) {
    return this.storage.executeSql(PARTYQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let objects: PARTY[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          objects.push(
            this.getPartyFromRow(res.rows, i)
          );
        }
      }
      return objects;
    })
  }

  async getUnBackedUpPayments(lastBackup: number) {
    return this.storage.executeSql(PAYMETNSQ.SELECT_FOR_BACKUP, [lastBackup]).then(res => {
      let objects: PAYMENT[] = [];
      if (res.rows.length > 0) {
        for (var i = 0; i < res.rows.length; i++) {
          objects.push(
            this.getPaymentsFromRow(res.rows, i)
          );
        }
      }
      return objects;
    })
  }
}
