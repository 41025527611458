import { CUSTOMER } from '../_models/customer.model'
import { PRODUCT } from '../_models/product.model'
import { USER } from '../_models/user.model'
import { INVOICE } from '../_models/invoice.model'
import { SETTINGS } from '../_models/settings.model'
import { ITEM_SALES } from '../_models/item-sales.model'
import { INVOICE_SETTINGS } from '../_models/invoice-settings.model'
import { PAYMENT } from '../_models/payments.model'
import { EXPENSE } from '../_models/expense.model'
import { PARTY } from '../_models/party.model'
import { PURCHASE } from '../_models/purchase.model'
import { BACKUP_STATUS } from '../_models/backup-status.model'

export const PRODUCTQ = {
    'GET_ALL_PRODUCTS': 'select * from products WHERE deleted_on IS NULL ORDER BY name ASC',
    'SELECT_FOR_BACKUP': 'select * from products where updated_on > ?',
    'GET_PRODUCT_BY_ID': 'select * from products where id = ?',
    'GET_PRODUCT_BY_BARCODE': 'select * from products where barcodes LIKE %?% AND deleted_on IS NULL',
    'UPDATE_PRODUCT_BY_ID': 'update products set name=?, price=?, tax=?, barcodes=?, unitType=?, unit=?, price_including_tax=?, quantity=?, cost_price=?, avg_cost_price=?, updated_on=?, deleted_on=? where id = ?',
    'DELETE_PRODUCT_BY_ID': 'update products set updated_on=?, deleted_on=? where id = ?',
    'DECREASE_PRODUCT_QUANTITY': 'UPDATE products SET updated_on=?, quantity = quantity - ? WHERE id = ?',
    'INCREASE_PRODUCT_QUANTITY': 'UPDATE products SET updated_on=?, quantity = quantity + ? WHERE id = ?',
    'INSERT_PRODUCT': 'INSERT into products (id, name, price, tax, barcodes, unitType, unit, price_including_tax, quantity, cost_price, avg_cost_price, uuid, updated_on, deleted_on) values(?, ? ,?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
}
export const CUSTOMERQ = {
    'SELECT_FOR_BACKUP': 'select * from customers where updated_on > ?',
    'GET_ALL_CUSTOMER': 'select * from customers WHERE deleted_on IS NULL ORDER BY customer_name ASC',
    'GET_CUSTOMER_BY_ID': 'select * from customers where id = ?',
    'UPDATE_CUSTOMER_BY_ID': 'update customers set customer_name=?, mobile_number=?, customer_gst=?, balance=?, total_purchase=?, updated_on=?, deleted_on=? where id = ?',
    'DELETE_CUSTOMER_BY_ID': 'update customers set updated_on=?, deleted_on=? where id = ?',
    'INSERT_CUSTOMER': 'INSERT into customers (id, customer_name, mobile_number, customer_gst, balance, total_purchase, uuid, updated_on, deleted_on) values(?, ?, ?, ?, ?, ?, ?, ?, ?)',
}
export const PARTYQ = {
    'SELECT_FOR_BACKUP': 'select * from parties where updated_on > ?',
    'GET_ALL_PARTY': 'select * from parties WHERE deleted_on IS NULL ORDER BY name ASC',
    'GET_PARTY_BY_ID': 'select * from parties where id = ?',
    'UPDATE_PARTY_BY_ID': 'update parties set name=?, mobile_number=?, gst=?, balance=?, total_purchase=?, updated_on=?, deleted_on=? where id = ?',
    'DELETE_PARTY_BY_ID': 'update parties set updated_on=?, deleted_on=? where id = ?',
    'INSERT_PARTY': 'INSERT into parties (id, name, mobile_number, gst, balance, total_purchase, uuid, updated_on, deleted_on) values(?, ?, ?, ?, ?, ?, ?, ?, ?)',
}
export const INVOICEQ = {
    'SELECT_FOR_BACKUP': 'select * from invoices where updated_on > ?',
    'GET_ALL_INVOICE': 'select * from invoices WHERE deleted_on IS NULL ORDER BY billdate DESC',
    'GET_ALL_BY_CUSTOMER_ID_AND_DATE': 'select * from invoices where deleted_on IS NULL AND customer_id = ? AND billdate BETWEEN ? AND ? ORDER BY id DESC',
    'GET_TOTAL_BY_CUSTOMER_ID_AND_DATE': 'select SUM(total) from invoices where deleted_on IS NULL AND customer_id = ? AND billdate BETWEEN ? AND ? ORDER BY billdate DESC',
    'GET_INVOICE_BY_PRODUCT_NAME_DATE_RANGE': 'select * from invoices where items LIKE %?% AND billdate BETWEEN ? AND ? AND deleted_on IS NULL ORDER BY billdate DESC',
    'GET_INVOICE_BY_DATE_RANGE': 'select * from invoices where billdate BETWEEN ? AND ? ORDER BY billdate DESC',
    'GET_INVOICE_BY_CUSTOMER_ID_WITH_BALANCE': 'select * from invoices where deleted_on IS NULL AND customer_id=? AND balance <> 0 ORDER BY billdate DESC',
    'GET_INVOICE_BY_ID': 'select * from invoices where id = ?',
    'UPDATE_INVOICE_BY_ID': 'update invoices set customer_id=?, items=?, billdate=?, total=?, payment_type=?, discount=?, customer_name=?, mobile_number=?, paid=?, balance=?, profit_loss=?, tax_amount=?, updated_on=?, deleted_on=?  where id = ?',
    'DELETE_INVOICE_BY_ID': 'update invoices set updated_on=?, deleted_on=? where id = ?',
    'INSERT_INVOICE': 'INSERT into invoices (id, customer_id, items, billdate, total, payment_type, discount, customer_name, mobile_number, paid, balance, profit_loss, tax_amount, uuid, updated_on, deleted_on) values(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
}
export const PURCHASEQ = {
    'SELECT_FOR_BACKUP': 'select * from purchase where updated_on > ?',
    'GET_ALL_PURCHASE': 'select * from purchase WHERE deleted_on IS NULL ORDER BY billdate DESC',
    'GET_INVOICE_BY_DATE_RANGE': 'select * from purchase where billdate BETWEEN ? AND ? AND deleted_on IS NULL ORDER BY billdate DESC',
    'GET_ALL_BY_PARTY_ID_AND_DATE': 'select * from purchase where party_id = ? AND billdate BETWEEN ? AND ? AND deleted_on IS NULL ORDER BY id DESC',
    'GET_TOTAL_BY_PARTY_ID_AND_DATE': 'select SUM(total) from purchase where party_id = ? AND billdate BETWEEN ? AND ? AND deleted_on IS NULL ORDER BY id DESC',
    'GET_PURCHASE_BY_PRODUCT_NAME_DATE_RANGE': 'select * from purchase where items LIKE %?% AND billdate AND deleted_on IS NULL BETWEEN ? AND ?',
    'GET_PURCHASE_BY_PARTY_ID_WITH_BALANCE': 'select * from purchase where party_id=? AND balance <> 0 AND deleted_on IS NULL',
    'GET_PURCHASE_BY_ID': 'select * from purchase where id = ?',
    'UPDATE_PURCHASE_BY_ID': 'update purchase set party_id=?, items=?, billdate=?, total=?, payment_type=?, invoice_number=?, party_name=?, mobile_number=?, paid=?, balance=?, tax_amount=?, updated_on=?, deleted_on=?  where id = ?',
    'DELETE_PURCHASE_BY_ID': 'update purchase set updated_on=?, deleted_on=? where id = ?',
    'INSERT_PURCHASE': 'INSERT into purchase (id, party_id, items, billdate, total, payment_type, invoice_number, party_name, mobile_number, paid, balance, tax_amount, uuid, updated_on, deleted_on) values(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
}
export const USERQ = {
    'SELECT_FOR_BACKUP': 'select * from user where updated_on > ?',
    'GET_ALL_USER': 'select * from user',
    'GET_USER_BY_ID': 'select * from user where id = ?',
    'UPDATE_USER_BY_ID': 'update user set user_full_name=?, email=?, mobile_number=?, shop_address=?, logo=?, shop_name=?, gst=?, upi=?, password=?, uuid=?, updated_on=?, deleted_on=? where id = ?',
    'DELETE_USER_BY_ID': 'update user set updated_on=?, deleted_on=? where id = ?',
    'INSERT_USER': 'INSERT into user (id, user_full_name, email, mobile_number, shop_address, logo, shop_name, gst, upi, password, uuid, updated_on, deleted_on) values(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
}
export const SETTINGSQ = {
    'SELECT_FOR_BACKUP': 'select * from settings where updated_on > ?',
    'GET_ALL': 'select * from settings',
    'GET_BY_ID': 'select * from settings where id = ?',
    'UPDATE_BY_ID': 'update settings set auto_backups=?, prints=?, show_tax_on_invoice=?, invoice_footer=?, whatsapp_receipts=?, printer_name=?, auto_send_sms=?, updated_on=?, deleted_on=? where id = ?',
    'DELETE_BY_ID': 'DELETE from settings where id = ?',
    'INSERT': 'INSERT into settings (id, auto_backups, prints, show_tax_on_invoice, invoice_footer, whatsapp_receipts, printer_name, auto_send_sms, uuid, updated_on, deleted_on) values(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
}

export const ITEM_SALESQ = {
    'SELECT_FOR_BACKUP': 'select * from item_sales where updated_on > ?',
    'GET_ALL': 'select * from item_sales WHERE deleted_on IS NULL',
    'GET_ALL_BY_PRODUCT_ID_AND_DATE': 'select * from item_sales where product_id = ? AND created_on BETWEEN ? AND ? AND deleted_on IS NULL ORDER BY id DESC',
    'GET_ALL_BY_PRODUCT_ID_AND_DATE_ASC': 'select * from item_sales where product_id = ? AND created_on BETWEEN ? AND ? AND deleted_on IS NULL ORDER BY id ASC',
    'GET_BY_ID': 'select * from item_sales where id = ?',
    'UPDATE_BY_ID': 'update item_sales set product_id = ?, quantity = ?, created_on = ?, _action = ?, invoice_id = ?, remaining_stock = ?, uuid = ?, updated_on=?, deleted_on=? where id = ?',
    'DELETE_BY_ID': 'update item_sales set updated_on=?, deleted_on=? where id = ?',
    'DELETE_BY_PRODUCT_AND_INVOICE_ID': 'update item_sales set updated_on=?, deleted_on=? where product_id = ? AND invoice_id = ?',
    'INSERT': 'INSERT into item_sales (id, product_id, quantity, created_on, _action, invoice_id, remaining_stock, uuid, updated_on, deleted_on) values(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
}

export const INVOICE_SETTINGSQ = {
    'SELECT_FOR_BACKUP': 'select * from invoice_settings where updated_on > ?',
    'GET_ALL': 'select * from invoice_settings',
    'GET_BY_ID': 'select * from invoice_settings where id = ?',
    'UPDATE_BY_ID': 'update invoice_settings set signature=?, invoice_prefix=?, terms_and_conditions=?, show_customer_name=?, template=?, updated_on=?, deleted_on=? where id = ?',
    'DELETE_BY_ID': 'DELETE from invoice_settings where id = ?',
    'INSERT': 'INSERT into invoice_settings (id, signature, invoice_prefix, terms_and_conditions, show_customer_name, template, uuid, updated_on, deleted_on) values(?, ?, ?, ?, ?, ?, ?, ?, ?)',
}

export const PAYMETNSQ = {
    'SELECT_FOR_BACKUP': 'select * from payments where updated_on > ?',
    'GET_ALL': 'select * from payments WHERE deleted_on IS NULL ORDER BY date DESC',
    'GET_BY_ID': 'select * from payments where id = ?',
    'GET_PAYMENTS_BY_CUSTOMER_ID': 'select * from payments where _from = ? AND deleted_on IS NULL ORDER BY date DESC',
    'GET_PAYMENTS_TO_PARTY_ID': 'select * from payments where _to = ? AND deleted_on IS NULL ORDER BY date DESC',
    'GET_INCOME_BY_DATE': 'select * from payments where date BETWEEN ? AND ? AND _to = "-1" AND deleted_on IS NULL',
    'GET_INCOME_BY_DATE_AND_TYPE': 'select * from payments where date BETWEEN ? AND ? AND _to = "-1" AND payment_type = ? AND deleted_on IS NULL',
    'GET_OUTGOING_BY_DATE_AND_TYPE': 'select * from payments where date BETWEEN ? AND ? AND _from = "-1" AND payment_type = ? AND deleted_on IS NULL',
    'GET_OUTGOING_BY_DATE': 'select * from payments where date BETWEEN ? AND ? AND _from = "-1" AND deleted_on IS NULL',
    'UPDATE_BY_ID': 'update payments set date=?, category=?, _from=?, _to=?, invoice_id=?, direction=?, amount=?, payment_type=?, updated_on=?, deleted_on=? where id = ?',
    'DELETE_BY_ID': 'update payments set updated_on=?, deleted_on=? where id = ?',
    'DELETE_PAYMENT_FOR_PAID_ZERO': 'DELETE FROM payments where amount = 0',
    'DELETE_BY_INVOICE_ID': 'update payments set updated_on=?, deleted_on=? where invoice_id = ?  AND category = "invoice"',
    'DELETE_BY_EXPENSE': 'update payments set updated_on=?, deleted_on=? where invoice_id = ?  AND category = ?',
    'DELETE_BY_PURCHASE_ID': 'update payments set updated_on=?, deleted_on=? where invoice_id = ? AND category = "purchase"',
    'INSERT': 'INSERT into payments (id, date, category, _from, _to, invoice_id, direction, amount, payment_type, uuid, updated_on, deleted_on) values(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
}

export const EXPENSEQ = {
    'SELECT_FOR_BACKUP': 'select * from expense where updated_on > ?',
    'GET_ALL': 'select * from expense WHERE deleted_on IS NULL',
    'GET_BY_ID': 'select * from expense where id = ?',
    'UPDATE_BY_ID': 'update expense set date=?, category=?, description=?, vendor_id=?, total_cost=?, paid=?, balance=?, payment_type=?, updated_on=?, deleted_on=? where id = ?',
    'DELETE_BY_ID': 'update expense set updated_on=?, deleted_on=? where id = ?',
    'INSERT': 'INSERT into expense (id, date, category, description, vendor_id, total_cost, paid, balance, payment_type, uuid, updated_on, deleted_on) values(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
}

export const BACKUP_STATUSQ = {
    'GET_ALL': 'select * from backup_status',
    'GET_BY_MODULE': 'select * from backup_status where module = ?',
    'UPDATE_LASTBACKUP': 'update backup_status set lastBackup=? where module = ?',
    'UPDATE_LASTRESTORED': 'update backup_status set lastRestored=? where module = ?',
    'DELETE': 'UPDATE from backup_status',
    'INSERT': 'INSERT into backup_status (uuid, lastBackup, lastRestored, module) values(?, ?, ?, ?)',
}

export function getProductFromRow(response: any, i: number): PRODUCT {
    return {
        id: response.item(i).id,
        name: response.item(i).name,
        price: response.item(i).price,
        tax: response.item(i).tax,
        barcodes: response.item(i).barcodes,
        unit: response.item(i).unit,
        unitType: response.item(i).unitType,
        price_including_tax: response.item(i).price_including_tax,
        quantity: response.item(i).quantity && !isNaN(+response.item(i).quantity) ? response.item(i).quantity : 0,
        cost_price: response.item(i).cost_price && !isNaN(+response.item(i).cost_price) ? response.item(i).cost_price : response.item(i).price,
        avg_cost_price: response.item(i).avg_cost_price && !isNaN(+response.item(i).avg_cost_price) ? response.item(i).avg_cost_price : response.item(i).price,
        uuid: response.item(i).uuid,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getCustomerFromRow(response: any, i: number): CUSTOMER {
    return {
        id: response.item(i).id,
        customer_name: response.item(i).customer_name,
        mobile_number: response.item(i).mobile_number,
        customer_gst: response.item(i).customer_gst,
        balance: response.item(i).balance,
        total_purchase: response.item(i).total_purchase,
        uuid: response.item(i).uuid,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getPartyFromRow(response: any, i: number): PARTY {
    return {
        id: response.item(i).id,
        name: response.item(i).name,
        mobile_number: response.item(i).mobile_number,
        gst: response.item(i).gst,
        balance: response.item(i).balance,
        total_purchase: response.item(i).total_purchase,
        uuid: response.item(i).uuid,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getUserFromRow(response: any, i: number): USER {
    return {
        id: response.item(i).id,
        user_full_name: response.item(i).user_full_name,
        email: response.item(i).email,
        shop_address: response.item(i).shop_address,
        shop_name: response.item(i).shop_name,
        mobile_number: response.item(i).mobile_number,
        logo: response.item(i).logo,
        gst: response.item(i).gst,
        password: response.item(i).password,
        uuid: response.item(i).uuid,
        upi: response.item(i).upi && response.item(i).upi.length > 0 ? response.item(i).upi : null,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getSettingsFromRow(response: any, i: number): SETTINGS {
    return {
        id: response.item(i).id,
        auto_backups: response.item(i).auto_backups,
        prints: response.item(i).prints,
        show_tax_on_invoice: response.item(i).show_tax_on_invoice,
        invoice_footer: response.item(i).invoice_footer,
        whatsapp_receipts: response.item(i).whatsapp_receipts,
        printer_name: response.item(i).printer_name,
        auto_send_sms: response.item(i).auto_send_sms ? response.item(i).auto_send_sms : true,
        uuid: response.item(i).uuid,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getInvoiceFromRow(response: any, i: number): INVOICE {
    return {
        id: response.item(i).id,
        customer_id: response.item(i).customer_id,
        items: response.item(i).items,
        billdate: response.item(i).billdate,
        total: response.item(i).total,
        payment_type: response.item(i).payment_type,
        discount: response.item(i).discount,
        customer_name: response.item(i).customer_name,
        mobile_number: Number(response.item(i).mobile_number) ? Number(response.item(i).mobile_number.toString().substring(0, 10)) : 10000000,
        paid: response.item(i).paid,
        balance: response.item(i).balance,
        profit_loss: response.item(i).profit_loss ? response.item(i).profit_loss : 0,
        tax_amount: response.item(i).tax_amount ? response.item(i).tax_amount : 0,
        uuid: response.item(i).uuid,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getPurchaseFromRow(response: any, i: number): PURCHASE {
    return {
        id: response.item(i).id,
        party_id: response.item(i).party_id,
        items: response.item(i).items,
        billdate: response.item(i).billdate,
        total: response.item(i).total,
        payment_type: response.item(i).payment_type,
        invoice_number: response.item(i).invoice_number,
        party_name: response.item(i).party_name,
        mobile_number: response.item(i).mobile_number,
        paid: response.item(i).paid,
        balance: response.item(i).balance,
        tax_amount: response.item(i).tax_amount ? response.item(i).tax_amount : 0,
        uuid: response.item(i).uuid,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getItemSalesRow(response: any, i: number): ITEM_SALES {
    return {
        id: response.item(i).id,
        productId: response.item(i).product_id,
        quantity: response.item(i).quantity,
        date: response.item(i).created_on,
        action: response.item(i)._action,
        invoice_id: response.item(i).invoice_id,
        remaining_stock: response.item(i).remaining_stock ? response.item(i).remaining_stock : 0,
        uuid: response.item(i).uuid,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getInvoiceSettingsFromRow(response: any, i: number): INVOICE_SETTINGS {
    return {
        id: response.item(i).id,
        signature: response.item(i).signature,
        invoice_prefix: response.item(i).invoice_prefix,
        show_customer_name: response.item(i).show_customer_name,
        template: response.item(i).template,
        terms_and_conditions: response.item(i).terms_and_conditions,
        uuid: response.item(i).uuid,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getPaymentsFromRow(response: any, i: number): PAYMENT {
    return {
        id: response.item(i).id,
        date: response.item(i).date,
        category: response.item(i).category,
        _from: response.item(i)._from,
        _to: response.item(i)._to,
        invoice_id: response.item(i).invoice_id,
        direction: response.item(i).direction,
        amount: response.item(i).amount,
        payment_type: response.item(i).payment_type,
        uuid: response.item(i).uuid,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getExpenseFromRow(response: any, i: number): EXPENSE {
    return {
        id: response.item(i).id,
        date: response.item(i).date,
        category: response.item(i).category,
        description: response.item(i).description,
        vendor_id: response.item(i).vendor_id,
        total_cost: response.item(i).total_cost,
        paid: response.item(i).paid,
        balance: response.item(i).balance,
        payment_type: response.item(i).payment_type,
        uuid: response.item(i).uuid,
        updated_on: response.item(i).updated_on, deleted_on: response.item(i).deleted_on
    }
}

export function getBackupStatusFromRow(response: any, i: number): BACKUP_STATUS {
    return {
        id: response.item(i).id,
        uuid: response.item(i).uuid,
        lastBackup: response.item(i).lastBackup,
        lastRestored: response.item(i).lastRestored,
        module: response.item(i).module,
    }
}