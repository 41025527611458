import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DbService } from './services/db.service';
import { USER } from './_models/user.model';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { LaunchReview } from '@ionic-native/launch-review/ngx';
import { File } from '@ionic-native/file/ngx';
import { DEFAULT_ICON } from './_constants/icon.constant';
import { AppVersion } from '@ionic-native/app-version/ngx';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex: string = 'dashboard';
  user: USER;
  icon = DEFAULT_ICON;
  public appPages = [
    {
      title: 'Dashboard',
      url: 'dashboard',
      icon: 'speedometer'
    },
    {
      title: 'Products',
      url: 'list-products',
      icon: 'pricetags'
    },
    {
      title: 'Customers',
      url: 'customer-list',
      icon: 'people'
    },
    {
      title: 'Invoices',
      url: 'invoice-list',
      icon: 'receipt'
    },
    {
      title: 'Parties',
      url: 'list-parties',
      icon: 'people-circle'
    },
    {
      title: 'Purchase',
      url: 'list-purchase',
      icon: 'download'
    },
    {
      title: 'Expense',
      url: 'list-expense',
      icon: 'barbell'
    },
    {
      title: 'Payments',
      url: 'payments',
      icon: 'cash'
    },
    {
      title: 'Reports',
      url: 'reports',
      icon: 'stats-chart'
    }
  ];

  otherPages = [
    {
      title: 'Settings',
      url: 'settings',
      icon: 'cog'
    },
    {
      title: 'About Us',
      url: 'about-us',
      icon: 'business'
    }
    // ,
    // {
    //   title: 'Order Printer',
    //   url: 'order-printer',
    //   icon: 'cart'
    // },
    // {
    //   title: 'Query/Feedback',
    //   url: 'query-feedback',
    //   icon: 'help'
    // }
  ]

  appVersionString = 'v';
  appPackage: string;


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private db: DbService,
    private router: Router,
    private socialSharing: SocialSharing,
    private launchReview: LaunchReview,
    private file: File,
    private appVersion: AppVersion
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.statusBar.backgroundColorByHexString('#1e5855');
      this.statusBar.overlaysWebView(false);

      if (!this.platform.is('desktop')) {
        this.file.removeRecursively(this.file.dataDirectory, 'bills').then(response => {
          //console.log('deleted all files');
          //console.log(JSON.stringify(response));
        })
        // const updateUrl = environment.baseUrl + 'app-update.xml';
        // this.appUpdate.checkAppUpdate(updateUrl).then(() => { //console.log('Update available') });

        this.appVersion.getVersionNumber().then(response => {
          this.appVersionString = 'v' + response;
        });

        this.appVersion.getPackageName().then(packageName => {
          this.appPackage = packageName;
        })
      }

    });
  }

  ngOnInit() {
    this.db.dbState().subscribe((res) => {
      if (res) {
        this.db.userAdded$.subscribe((res) => {
          this.fetchUsers();
        });
      }
    });
  }

  rateTheApp() {
    if (this.launchReview.isRatingSupported()) {
      this.launchReview.launch().then((rating) => {
        //console.log(rating)
      });
    }
  }

  shareTheApp() {
    this.socialSharing.share('Download this amazing billing app right now. Please rate it on the store.', 'EZ Billing Retail POS', this.icon, `https://play.google.com/store/apps/details?id=${this.appPackage}`).then(() => {
      //console.log("WhatsApp share successful");
    }).catch(error => {
      //console.log("WhatsApp error");
      ////console.log(error);
    });
  }

  fetchUsers() {
    this.db.getUser().then(item => {
      this.user = item
      if (!this.user || this.user == undefined) {
        this.router.navigateByUrl('/login');
      } else {
        this.backup(true);
        setInterval(() => {
          this.backup(false);
        }, 5000);
        if (this.router.url === '/') {
          this.router.navigateByUrl('/dashboard');
          this.selectedIndex = 'dashboard';
        } else {
          this.selectedIndex = this.router.url.split('/')[1];
        }
      }
    }).catch((error) => {
      ////console.log(error)
      this.router.navigateByUrl('/login');
    })
  }

  async backup(restore) {
    await this.db.backup(restore);
  }
}
